<template>
  <a-row :gutter="[30, 20]">
    <a-col :span="15">
      <div ref="BarChart" class="chart-item"></div>
    </a-col>
    <a-col :span="9">
      <div ref="PieChart" class="chart-item"></div>
    </a-col>
  </a-row>
</template>
<script>
import { Chart } from '@antv/g2'
export default {
  name: "SmsChart",
  components: {
  },
  props: {
    height: {
      type: String,
      default: '280px'
    }
  },

  data() {
    return {
      chart: null,
      // 查询参数
      queryParams: {
        date: null
      },
      data:[],
      xdata:[]
    }
  },
  mounted() {

  },

  methods: {
// 渲染图表
    renderChart(options = {}) {
      console.log(options)
      const { ref, data, type } = options,
        chart = new Chart({
          container: this.$refs[ref],
          autoFit: true,
          height: 300
        })
      if (this[`chartIns-${type}`]) return this[`chartIns-${type}`].changeData(data)
      chart
        .data(data)
        .scale('sales', { nice: true })
        .tooltip({ showMarker: false, shared: true })

      this[`chartIns-${type}`] = chart

      chart.legend({ position: 'bottom', offsetY: 10 })
      if (['bar'].includes(type)) {
        chart.interval().adjust('stack')
          .position('date*value').color('name')
          .label('value', { position: 'middle' })

        chart.interaction('active-region')
      }
      if (['pie'].includes(type)) {
        chart.coordinate('theta', { radius: 0.6 })
        chart.interval()
          .position('percent')
          .color('name')
          .label('percent', {
            content: (data) => `${data.name}: ${parseInt(data.percent * 100)}%`
          })
          .adjust('stack')

        chart.interaction('element-active')
      }

      chart.render()
    }

  }
}
</script>
<style scoped lang="less">
</style>
